import { IThunderbolt } from '../features/thunderbolt/IThunderbolt'
import { Thunderbolt } from '../features/thunderbolt/types'
import { createEnvLoader } from '../features/env'
import { IocContainer } from '@wix/thunderbolt-ioc'
import { Environment } from '../types/Environment'
import {
	BIReporter,
	ILogger,
	IPageAssetsLoader,
	MasterPageFeatureConfigSymbol,
	PageAssetsLoaderSymbol,
} from '@wix/thunderbolt-symbols'
import { DynamicSessionModel, DynamicSessionModelSymbol } from 'feature-session-manager'
import { taskify } from '@wix/thunderbolt-commons'

export interface ThunderboltInitializer {
	getThunderboltInvoker(environment: Environment): Promise<() => Promise<IThunderbolt>>
	loadEnvironmentAndSiteFeatures(environment: Environment): void
}

const loadMasterPageFeaturesConfigs = async (container: IocContainer) => {
	// This adds the master page structure and props to the fetchCache
	const assetsLoader = container.get<IPageAssetsLoader>(PageAssetsLoaderSymbol)
	const siteFeaturesConfigs = await assetsLoader.load('masterPage').siteFeaturesConfigs

	Object.entries(siteFeaturesConfigs).forEach(([featureName, featureConfig]) => {
		container
			.bind(MasterPageFeatureConfigSymbol)
			.toConstantValue(featureConfig)
			.whenTargetNamed(featureName)
	})
}

const loadDynamicModel = async (
	container: IocContainer,
	{ biReporter, logger }: { biReporter: BIReporter; logger: ILogger }
) => {
	const dynamicModelRaw = await window.fetchDynamicModel
	if (typeof dynamicModelRaw === 'object') {
		container.bind<DynamicSessionModel>(DynamicSessionModelSymbol).toConstantValue(dynamicModelRaw)
		const { visitorId, siteMemberId } = dynamicModelRaw
		biReporter.setDynamicSessionData({ visitorId, siteMemberId })
	} else {
		logger.captureError(new Error(`failed fetching dynamicModel`), {
			tags: { fetchFail: 'dynamicModel' },
			extras: { errorMessage: dynamicModelRaw },
		})
	}
}

export const loadEnvironmentAndSiteFeatures = async (environment: Environment, container: IocContainer) => {
	container.load(createEnvLoader(environment))

	const { viewerModel, specificEnvFeaturesLoaders } = environment

	await taskify(() => specificEnvFeaturesLoaders.loadSiteFeatures(container, viewerModel.siteFeatures))
}

export const getThunderboltInvoker = async (
	environment: Environment,
	container: IocContainer
): Promise<() => Promise<IThunderbolt>> => {
	return async () => {
		const { biReporter, logger } = environment

		await taskify(() => loadMasterPageFeaturesConfigs(container))

		if (process.env.browser) {
			await taskify(() => loadDynamicModel(container, { biReporter, logger }))
		}

		const thunderbolt = await taskify(() => container.get<IThunderbolt>(Thunderbolt))

		await taskify(() => thunderbolt.ready())

		return thunderbolt
	}
}

export const ThunderboltInitializerImpl: (container: IocContainer) => ThunderboltInitializer = (
	container: IocContainer
) => ({
	loadEnvironmentAndSiteFeatures: (environment) => loadEnvironmentAndSiteFeatures(environment, container),
	getThunderboltInvoker: (environment) => getThunderboltInvoker(environment, container),
})
